// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-docs-js": () => import("./../src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-index-ja-js": () => import("./../src/pages/index.ja.js" /* webpackChunkName: "component---src-pages-index-ja-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-templates-js": () => import("./../src/pages/templates.js" /* webpackChunkName: "component---src-pages-templates-js" */),
  "component---src-templates-documentation-js": () => import("./../src/templates/documentation.js" /* webpackChunkName: "component---src-templates-documentation-js" */),
  "component---src-templates-redirect-js": () => import("./../src/templates/redirect.js" /* webpackChunkName: "component---src-templates-redirect-js" */)
}

